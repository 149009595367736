input, select, textarea {
    width: 100%;
    padding: 8px 12px;
    outline: none;
    min-height: 36px;
    border-radius: 4px;
    background: white;
    border: .5px solid rgba(0, 0, 0, 0.1);
    font-weight: normal;
    transition: ease 250ms;
    color: rgba(0, 0, 0, 0.75);
    &:focus {
        border: .5px solid rgba(0, 0, 255, 0.5);
    }
}
// select placeholder
option[value=''][disabled] {
    display: none;
}

// banner input
.banner__wrapper {
    width: 100%;
    height: 160px;
    border-radius: 4px;
    border: .5px solid rgba(0, 0, 0, 0.1);
    input[type="file"] {
        display: none;
    }
}