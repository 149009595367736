@import './css/constants';
@import './css/button';
@import './css/display';
@import './css/spacing';
@import './css/input';

:root {
    --primary-color: #373cd7;
    --secondary-color: #eef6ff;
}

html, body, * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 12px;
    text-decoration: none;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/* For WebKit-based browsers (Chrome, Safari, Opera, Firefox, Internet Explorer and Edge) */
::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

// app
.app {
    width: 100%;
    height: 100vh;
    display: flex;
    overflow:hidden;
    background: rgba(128, 128, 128, 0.1);
    padding: 16px;
    gap: $radius;
    .side__menu {
        width: 256px;
        height: calc(100vh - 32px);
        background: white;
        position: sticky;
        border-radius: $radius;
        padding: calc($radius / 2);
        display: flex;
        flex-direction: column;
        transition: width 250ms ease;
        
        .side__menu__list {
            flex: 1;
            display: flex;
            flex-direction: column;
            a {
                height: 40px;
                padding: 8px 16px;
                color: grey;
                border-radius: calc($radius / 2);
                display: flex;
                align-items: center;
                gap: 8px;
                svg {
                    height: 16px;
                    width: 16px;
                }
            
                &.active {
                    background: blue;
                    color: white;
                }
            }
        }
    }
    section {
        width: calc(100% - 256px);
        display: flex;
        flex-direction: column;
        overflow: scroll;
        header , footer{
            z-index: 1;
            height: 60px;
            position: sticky;
            background: white;
            border-radius: $radius;
        }
        header {
            top: 0;
            button {
                background: white;
                    border: .5px solid rgba(0, 0, 0, 0.15);
                
                    svg {
                        color: rgba(0, 0, 0, 0.6);
                    }
            }
        }
        .body {
            flex: 1;
            margin: $radius 0;
            border-radius: $radius;
            background: white;
            overflow: hidden;
            .innder__body {
                flex: 1;
                margin: $radius;
                overflow: scroll;
            }
        }
        footer {
            bottom: 0;
        }
    }
}

/* text elipsis... */
.elipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

// login
.login {
    width: 100%;
    height: 100vh;

    .login__container {
        width: 100%;
        margin: 0 auto;
        max-width: 500px;
        border-radius: 12px;
        background: white;
        border: .5px solid rgb(156 39 176 / 10%);
    }
}

table {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    border-collapse: collapse;
    thead {
        position: sticky;
        height: 40px;
        top: 0;
        z-index: 1;
        background: white;
        box-shadow: 0 5px 15px 0 rgb(156 39 176 / 10%);
        th {
            font-size: 12px;
            text-align: left;
        }
    }
    th, td {
        padding: 8px 12px;
    }
    td {
        font-size: 10px;
        color: rgba(0, 0, 0, 0.75);
    }
    tbody {
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
    }
}

// loader
.loader__container {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.25);
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background:
        radial-gradient(farthest-side, var(--primary-color) 94%, #0000) top/6px 6px no-repeat,
        conic-gradient(#0000 30%, var(--primary-color));
    -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
    animation: loader__animation 1s infinite linear;
}

@keyframes loader__animation {
    100% {
        transform: rotate(1turn)
    }
}

// dot loader
.dot__loader {
    width: 60px;
    aspect-ratio: 2;
    --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 50%;
    animation: l3 1s infinite linear;
}

@keyframes l3 {
    20% {
        background-position: 0% 0%, 50% 50%, 100% 50%
    }

    40% {
        background-position: 0% 100%, 50% 0%, 100% 50%
    }

    60% {
        background-position: 0% 50%, 50% 100%, 100% 0%
    }

    80% {
        background-position: 0% 50%, 50% 50%, 100% 100%
    }
}

// form
.form__group {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(auto-fit, minmax(15%, 1fr));
    @media screen and (min-width: 0px) {
        grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    }
    @media screen and (min-width: 576px) {
        grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
    } 
    @media screen and (min-width: 768px) {
        grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
    }
    @media screen and (min-width: 960px) {
        grid-template-columns: repeat(auto-fit, minmax(22%, 1fr));
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
    }
}

// tag 
.tag {
    text-transform: uppercase;
    padding: 4px;
    font-size: 8px;
    color: white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
}

// action menu
.action__menu {
    position: relative;
    display: inline-block;

    .action__menu__dropdown {
        position: absolute;
        top: 50%;
        right: 100%;
        background-color: #fff;
        box-shadow: 0 3px 6px rgb(156 39 176 / 10%);
        border-radius: 8px;
        min-width: 100px;
        padding: 16px;
        transition: height 0.3s ease-in-out;
        transform: translate(0px, -50%);
        button {
            padding: 8px 4px !important;
        }
    }
}