button {
    height: 32px;
    outline: none;
    border: none;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background: blue;
    color: white;
    transition: all 250ms;
    font-size: 12px;
    padding: 8px 12px;

    &.small {
        height: 20px !important;
        padding: 6px 8px !important;
        font-size: 10px !important;
        gap: 6px;
        svg {
            height: 12px;
            width: 12px;
        }
    }
    &.danger {
        background: red !important;
    }

    &:disabled {
        cursor: not-allowed !important;
    }

    &.icon {
        width: 32px;
        border-radius: 50%;
        padding: 0px !important;
    }

    &.square {
        width: 32px;
        padding: 0;
    }
    &.square.small, &.icon.small {
        width: 20px;
    }

    &.link {
        background: none !important;
        color: var(--primary-color);
    }
}