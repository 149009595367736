.tabbar {

    // Tab List
    .tabs__list {
        display: flex;
        list-style: none;
        padding: 0;
        margin-bottom: 24px;
        overflow-y: scroll;
        border-bottom: 1px solid #e0e0e0; // Light divider

        li {
            padding: 10px 16px;
            cursor: pointer;
            font-weight: 500;
            color: #555;
            border-bottom: 2px solid transparent; // For active state transition
            transition: color 0.2s ease, border-color 0.2s ease; // Smooth transitions
            display: flex;
            white-space: nowrap;
            align-items: center;
            text-transform: capitalize;

            &.active {
                color: var(--primary-color); // Match your primary button color
                border-bottom-color: var(--primary-color);
            }
        }
    }

    // Tab Content
    .tabs__content {
        opacity: 0;
        transform: translateY(-10px);
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

        &.active {
            opacity: 1;
            transform: translateY(0);
        }
    }
}