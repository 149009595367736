.dialog {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); // Semi-transparent backdrop
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; 
    padding: 24px;

    .dialog__container {
        width: 100%;
        background-color: white;
        border-radius: 8px; 
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); 
        padding: 40px; 
        max-width: 1000px; 
        max-height: 80vh;
        transition: transform 0.3s ease-in-out;
        animation: x 250ms linear;
        overflow: scroll;
    }
}

@keyframes x {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}